import React, { Component } from "react"
import Axios from 'axios'

import Message from "../layout/Message"
import Spinner from "../layout/Spinner"
import ErrorServerLog from "../errors/ErrorServerLog"

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            permissions: '',
            password: '',
            password2: '',
            loading: true,
            error: null
        }
    }

    componentDidMount() {
        Axios.get('https://api.barnes-dvf.fr/api/users/self', {withCredentials: true})
            .then(res => {
                if (res.data.error) {
                    this.setState({loading: false, error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({
                        email: res.data.email,
                        name: res.data.name,
                        permissions: res.data.permissions,
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({loading: false, error: <Message message="Une erreur est survenue, merci de réessayer plus tard" type="danger" hidden={false} />})
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})
        Axios.patch('https://api.barnes-dvf.fr/api/users/self', 
            {
                name: this.state.name,
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2
            }, {withCredentials: true})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Modifications réussies" type="success" hidden={false} />})
                }
            })
            .catch(err => {
                this.setState({loading: false, error: <Message message="Une erreur est survenue, merci de réessayer plus tard" type="danger" hidden={false} />})
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
    }

    render() {
        return (
            <div className="container col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <h5 className="card-title text-center">Mon Profil</h5>
                        {this.state.error}
                        <form id="profilForm" onSubmit={this.onSubmit}>
                        <div className="form-group">
                                <label htmlFor="email">Adresse Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Adresse Email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Nom</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Nom"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="permissions">Permissions</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="permissions"
                                    placeholder="Permissions"
                                    disabled
                                    value={this.state.permissions}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Modifier le mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Mot de Passe"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">Répéter le Mot de Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password2"
                                    name="password2"
                                    placeholder="Répéter le Mot de Passe"
                                    value={this.state.password2}
                                    onChange={this.handleInputChange}/>
                            </div>
                            <button type="submit" className="btn btn-block btn-primary">Modifier Mon Profil</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile