import React, { Component } from "react"
import Axios from "axios"

import Message from "../layout/Message"
import Spinner from "../layout/Spinner"
import ErrorServerLog from "../errors/ErrorServerLog"

class LoginForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email : '',
            password: '',
            error: [],
            loading: false
        }
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})
        Axios.post('https://api.barnes-dvf.fr/api/users/login', {
            email: this.state.email,
            password: this.state.password,
        }, {withCredentials: true})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: <Message message="Connexion réussi, redirection..." type="success" dismiss="false" hidden={false} />})
                    sessionStorage.setItem('permissions', res.data.permissions)
                    this.props.update()
                    this.props.history.push('/appdvf')
                }
            })
            .catch(err => {
                this.setState({loading: false, error: <Message message="Une erreur est survenue, merci de réessayer plus tard" type="danger" hidden={false} />})
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
    }

    render() {
        return (
            <div>
                <div className="container col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                    <div className="card">
                        {this.state.loading ? <Spinner /> : null}
                        <div className="card-body">
                            <h5 className="card-title text-center">Se Connecter</h5>
                            {this.state.error}
                            <form id="loginForm" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Adresse Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Adresse Email"
                                        value={this.state.email}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Mot de Passe</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        placeholder="Mot de Passe"
                                        value={this.state.password}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <button type="submit" className="btn btn-block btn-primary">Se Connecter</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginForm