import React, { Component } from "react"

class Error404 extends Component {
    render() {
        return (
            <div className="text-center">
                <h1>Error 404 Not Found</h1>
                <h2>La page que vous avez demandée n'existe pas.</h2>
            </div>
        )
    }
}

export default Error404