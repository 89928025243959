import React, { Component } from "react"

import "./Footer.css"

class Footer extends Component {
    render() {
        return (
            <footer className="d-flex align-items-center" id="appFooter">
                <div className="container">
                    <p className="my-0">&copy; Barnes 2019</p>
                </div>
            </footer>
        )
    }
}

export default Footer