import React from "react"
import { Route, Redirect } from "react-router-dom"

export const PrivateRoute = ({component: Component, componentProps, loggedIn, ...rest}) => (
    <Route
        {...rest}
        render={props => loggedIn
            ? (<Component {...props} {...componentProps} />)
            : (<Redirect to={{pathname: "/", state: {from: props.location}}} />)
        }
    />
)