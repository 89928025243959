import React, { Component } from "react"
import Axios from 'axios'
import Autosuggest from 'react-autosuggest'

import Spinner from '../layout/Spinner'
import Message from '../layout/Message'
import ErrorServerLog from '../errors/ErrorServerLog'

import './DVF.css'

import $ from 'jquery'
import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5.min.js'
import 'datatables.net-colreorder-bs4'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'
import 'datatables.net-keytable-bs4'
import 'datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css'
import JSZip from 'jszip'
window.JSZip = JSZip

const getSuggestionValue = suggestion => suggestion.name
  
const renderSuggestion = (suggestion, {query, isHighlited}) => (
    <div>
        {suggestion.name}
    </div>
)

function createUserTable(DOMTable) {
    var tableFields = [
        {'data': 'date',
        'title': 'Date',
        'type': 'date',
        'render':
            (data, type, row) => {
                if(type === "sort") {
                    return new Date(data.split('-')[2] + '-' + data.split('-')[1] + '-' + data.split('-')[0])
                }
                return data
            },
        'defaultContent': ''},
        {'data': 'nature',
        'title': 'Nature Mutation',
        'defaultContent': ''},
        {'data': 'valeur_fonciere',
        'title': 'Valeur Foncière',
        'defaultContent': ''},
        {'data': 'surfaces.reelle_bati',
        'title': 'Surface Réelle',
        'defaultContent': ''},
        {'data': 'local.type',
        'title': 'Type de Bien',
        'defaultContent': ''},
        {'data': 'pieces_principales',
        'title': 'Nombre de Pièces Principales',
        'defaultContent': ''},
        {'data': 'localisation.adresse.code_voie',
        'title': 'Code Voie',
        'defaultContent': ''},
        {'data': 'localisation.adresse',
        'title': 'Adresse',
        'render':
            (data, type, row) => {
                var adresseComplete = !isNaN(parseInt(data.numero)) ? data.numero + ' ' : ''
                adresseComplete += typeof data.suffixe != 'undefined' ? data.suffixe + ' ' : ''
                adresseComplete += typeof data.nom_voie != 'undefined' ? data.nom_voie.toLowerCase() + ' ' : ''
                adresseComplete += typeof data.code_postal != 'undefined' ? data.code_postal + ' ' : ''
                adresseComplete += typeof data.nom_commune != 'undefined' ? data.nom_commune + ' ' : ''
                return adresseComplete
            }
        },
        {'data': 'lots.lot.0.numero',
        'title': 'Lot 1 numero',
        'defaultContent': ''},
        {'data': 'lots.lot.0.surface_carrez',
        'title': 'Lot 1 Surface',
        'defaultContent': ''},
        {'data': 'lots.lot.1.numero',
        'title': 'Lot 2 numero',
        'defaultContent': ''},
        {'data': 'lots.lot.1.surface_carrez',
        'title': 'Lot 2 Surface',
        'defaultContent': ''},
        {'data': 'lots.lot.2.numero',
        'title': 'Lot 3 numero',
        'defaultContent': ''},
        {'data': 'lots.lot.2.surface_carrez',
        'title': 'Lot 3 Surface',
        'defaultContent': ''},
        {'data': 'lots.lot.3.numero',
        'title': 'Lot 4 numero',
        'defaultContent': ''},
        {'data': 'lots.lot.3.surface_carrez',
        'title': 'Lot 4 Surface',
        'defaultContent': ''},
        {'data': 'lots.lot.4.numero',
        'title': 'Lot 5 numero',
        'defaultContent': ''},
        {'data': 'lots.lot.4.surface_carrez',
        'title': 'Lot 5 Surface',
        'defaultContent': ''},
    ]

    var table = DOMTable.DataTable({
        columns: tableFields,
        scrollX: true,
        searching: false,
        colReorder: true,
        scrollY: '60vh',
        autoWidth: false,
        keys: true,
        scrollCollapse: true,
        orderCellsTop: true,
        "lengthMenu": [10, 25, 50],
        dom: "<'row d-flex justify-content-between mx-auto'lB>" +
        "<'row text-nowrap'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        buttons: [
            {
                extend: 'excel',
                text: 'Excel',
                filename: ('Résultats DVF ' + new Date().toLocaleDateString('fr-FR')),
                title: '',
            },
            {
                extend: 'copy',
                text: 'Copier',
                title: '',
                exportOptions: {
                    rows: ':visible'
                }
            }
        ],
        language: {
            processing:     "Traitement en cours...",
            lengthMenu:     "Afficher _MENU_ &eacute;l&eacute;ments",
            info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
            infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
            infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun &eacute;l&eacute;ment ne correspondent",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Pr&eacute;c&eacute;dent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        },
    })

    return table
}

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: "",
            error: null,
            address: "",
            radiusSize: "",
            roomsMin: "",
            roomsMax: "",
            surfaceMin: "",
            surfaceMax: "",
            priceMin: "",
            priceMax: "",
            maxYear: "",
            radiusOrStreet: "street",
            natures: [],
            localTypes: [],
            natureMutation: [],
            localType: [],
            radiusSizePlaceHolder: "Sur (en m, 0 pour toute la rue)",
            suggestions: []
        }
    }

    onSuggestionsFetchRequested = ({ value }) => {
        if(value.length <= 3) {
            return this.setState({
                suggestions: []
            })
        }
        Axios.get('https://api-adresse.data.gouv.fr/search/', {params: {q: value}, headers: null})
            .then(res => {
                var suggestions = res.data.features.map(item => {
                    return {name: item.properties.label}
                })
                this.setState({
                    suggestions: suggestions
                })
            })
            .catch(err => {
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
                return []
            })
    }

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        })
    }

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({address: suggestionValue})
    }

    componentWillMount() {
        Axios.get('https://api.barnes-dvf.fr/api/mutations/natures', {withCredentials: true})
            .then(res => {
                this.setState({
                    natures: res.data.results.sort()
                })
            })
            .catch(err => {
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
        Axios.get('https://api.barnes-dvf.fr/api/mutations/localTypes', {withCredentials: true})
            .then(res => {
                this.setState({
                    localTypes: res.data.results.filter(val => isNaN(val)).sort()
                })
            })
            .catch(err => {
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
    }

    componentDidMount() {
        this.DVFDataTable = createUserTable($(this.DVFTable))
    }

    handleInputChange = event => {
        const {value, name} = event.target
        this.setState({
            [name]: value
        })
        if(name === "radiusOrStreet") {
            if(value === "street") {
                this.setState({
                    radiusSizePlaceHolder: "Sur (en m, 0 pour toute la rue)"
                })
            } else if(value === "radius") {
                this.setState({
                    radiusSizePlaceHolder: "Rayon (en m)*"
                })
            }
        }
    }

    handleMultipleSelectChange = event => {
        const {options, name} = event.target
        var value = []
        for (var i = 0, l = options.length; i < l; i++) {
          if (options[i].selected) {
            value.push(options[i].value)
          }
        }
        this.setState({
            [name]: value
        })
    }

    updateDataTable = (data) => {
        this.DVFDataTable.clear()
        this.DVFDataTable.rows.add(data)
        this.DVFDataTable.columns.adjust()
        this.DVFDataTable.draw()
    }

    componentWillUnmount() {
        if(this.DVFDataTable){
            this.DVFDataTable.destroy()
        }
    }

    onSubmit = event => {
        event.preventDefault()
        this.setState({loading: true})

        var {address, maxYear, radiusOrStreet, radiusSize, roomsMin, roomsMax, surfaceMin, surfaceMax, priceMin, priceMax, natureMutation, localType} = this.state

        Axios.get('https://api.barnes-dvf.fr/api/mutations', {params: { address, maxYear, radiusOrStreet, radiusSize, roomsMin, roomsMax, surfaceMin, surfaceMax, priceMin, priceMax, natureMutation, localType }, withCredentials: true})
            .then(res => {
                this.setState({loading: false})
                if (res.data.error) {
                    this.setState({error: <Message message={res.data.error_msg} type="warning" hidden={false} />})
                } else {
                    this.setState({error: null})
                    this.updateDataTable(res.data.results)
                }
            })
            .catch(err => {
                this.setState({loading: false, error: <Message message="Une erreur est survenue, merci de réessayer plus tard" type="danger" hidden={false} />})
                if(err.response) {
                    if(err.response.status === 500) {
                        return console.error('Error 500')
                    }
                }
                ErrorServerLog(err)
            })
    }

    render() {
        return(
            <div className="container col-sm-12 col-md-10 col-lg-9 col-xl-8">
                <div className="card">
                    {this.state.loading ? <Spinner /> : null}
                    <div className="card-body">
                        <h5 className="card-title text-center">Application DVF</h5>
                        {this.state.error}
                        <form autoComplete="off" onSubmit={this.onSubmit} className="my-2">
                            <div className="form-row align-items-end">
                                <div className="col-md-8 col-sm-8" id="addressSearch">
                                    <label htmlFor="address">Autour du*: </label>
                                    <Autosuggest
                                        suggestions={this.state.suggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={{
                                            placeholder: 'Adresse*',
                                            value: this.state.address,
                                            onChange: this.handleInputChange,
                                            className: 'form-control',
                                            name: 'address',
                                            autoComplete: 'new-text'
                                        }}
                                        onSuggestionSelected={this.onSuggestionSelected}/>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <label htmlFor="maxYear">Date maximum*: </label>
                                    <select className="custom-select" id="maxYear" name="maxYear" value={this.state.maxYear} onChange={this.handleInputChange}>
                                        <option disabled value="">Année</option>
                                        <option value="2022">2022</option>
                                        <option value="2021">2021</option>
                                        <option value="2020">2020</option>
                                        <option value="2019">2019</option>
                                        <option value="2018">2018</option>
                                        <option value="2017">2017</option>
                                        <option value="2016">2016</option>
                                        <option value="2015">2015</option>
                                        <option value="2014">2014</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row align-items-center my-2">
                                <div className="form-check form-check-inline col-md-3 col-sm-6 mx-0 my-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radiusOrStreet"
                                        id="streetRadio"
                                        value="street"
                                        checked={this.state.radiusOrStreet === "street"}
                                        onChange={this.handleInputChange}/>
                                    <label className="form-check-label col-form" htmlFor="streetRadio">Dans la même rue ou ville*</label>
                                </div>
                                <div className="form-check form-check-inline col-md-3 col-sm-6 mx-0 my-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="radiusOrStreet"
                                        id="radiusRadio"
                                        value="radius" 
                                        checked={this.state.radiusOrStreet === "radius"}
                                        onChange={this.handleInputChange}/>
                                    <label className="form-check-label" htmlFor="radiusRadio">Dans un rayon*</label>
                                </div>
                                <div className="col-md-5 col-sm offset-md-1">
                                    <input
                                        type="number"
                                        className="form-control align-items-end"
                                        id="radiusSize"
                                        name="radiusSize"
                                        placeholder={this.state.radiusSizePlaceHolder}
                                        value = {this.state.radiusSize}
                                        onChange = {this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6 col-sm">
                                    <label htmlFor="natureMutation">Nature(s) mutations: </label>
                                    <select
                                        className="custom-select form-control"
                                        id="natureMutation"
                                        name="natureMutation"
                                        multiple={true}
                                        size="3"
                                        value={this.state.natureMutation}
                                        onChange={this.handleMultipleSelectChange}>
                                        {this.state.natures.map(nature => <option key={nature} value={nature}>{nature}</option>)}
                                    </select>
                                </div>
                                <div className="form-group col-md-6 col-sm">
                                    <label htmlFor="localType">Type(s) de biens: </label>
                                    <select
                                        className="custom-select form-control"
                                        id="localType"
                                        name="localType"
                                        multiple={true}
                                        size="3"
                                        value={this.state.localType}
                                        onChange={this.handleMultipleSelectChange}>
                                        {this.state.localTypes.map(localType => <option key={localType} value={localType}>{localType}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row align-items-end">
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="surfaceMin">Surface Min:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="surfaceMin"
                                        name="surfaceMin"
                                        placeholder="Surface Min"
                                        value={this.state.surfaceMin}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="surfaceMax">Surface Max:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="surfaceMax"
                                        name="surfaceMax"
                                        placeholder="Surface Max"
                                        value={this.state.surfaceMax}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="priceMin">Valeur Min:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="priceMin"
                                        name="priceMin"
                                        placeholder="Valeur Min"
                                        value={this.state.priceMin}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="priceMax">Valeur Max:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="priceMax"
                                        name="priceMax"
                                        placeholder="Valeur Max"
                                        value={this.state.priceMax}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="roomsMin">Pièces Min:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="roomsMin"
                                        name="roomsMin"
                                        placeholder="Pièces Min"
                                        value={this.state.roomsMin}
                                        onChange={this.handleInputChange}/>
                                </div>
                                <div className="form-group col-md-2 col-sm-6">
                                    <label htmlFor="roomsMax">Pièces Max:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="roomsMax"
                                        name="roomsMax"
                                        placeholder="Pièces Max"
                                        value={this.state.roomsMax}
                                        onChange={this.handleInputChange}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <button type="submit" className="btn btn-primary btn-block mx-1 form-control">Valider</button>
                            </div>
                        </form>
                        <table className="table bg-light table-striped table-bordered table-hover w-100" id="DVFTable" ref={el => this.DVFTable = el}>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default App
