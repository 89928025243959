import React, { Component } from "react"
import { Redirect } from "react-router-dom"

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.props.loggedIn) {
                return this.setState({redirect: "/appdvf"})
            }
            return this.setState({redirect: "/login"})
        }, 300)
    }

    render() {
        if(this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return <h1 className="text-center">Redirection...</h1>
    }
}

export default Index