import React, { Component } from "react"

class Message extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden : props.hidden ? true : false,
            message: props.message,
            type: props.type
        }
    }

    hideAlert() {
        this.setState({hidden: true})
    }

    componentWillReceiveProps(nextProps) {
        this.setState({hidden: nextProps.hidden, message: nextProps.message, type: nextProps.type})
    }

    render() {
        if (!this.state.hidden) {
            return (
                <div className={"alert alert-dismissible fade show alert-" + (this.state.type ? this.state.type : "warning")} role="alert">
                    {this.state.message}
                    <button type="button" className="close" onClick={() => this.hideAlert()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        }
        return null
    }
}

export default Message