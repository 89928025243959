import React, { Component} from 'react'
import Axios from "axios"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"

import "./App.css"

import NavBar, { NavLink, NavButton } from "./components/layout/Navbar"
import LoginForm from "./components/forms/Login"
import { PrivateRoute } from "./components/PrivateRoute"
import Profil from "./components/profile/Profile"
import DVFApp from "./components/Apps/DVF"
import Admin from "./components/admin/Admin"
import AppFooter from "./components/layout/Footer"
import Index from './components/index/Index'
import Error404 from './components/errors/404'
import Error500 from './components/errors/500'
import ErrorServerLog from './components/errors/ErrorServerLog'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loggedIn: false,
            permissions: "",
            error500: false
        }
    }

    componentWillMount() {
        Axios.get('https://api.barnes-dvf.fr/api/users/self', {withCredentials: true})
            .then(res => {
                if(!res.data.error) {
                    sessionStorage.setItem('permissions', res.data.permissions)
                    this.setState({permissions: res.data.permissions, loggedIn: true})
                } else {
                    throw new Error(res.data.error_msg)
                }
            })
            .catch(err => {
                sessionStorage.removeItem('permissions')
                this.setState({permissions: "", loggedIn: false})
                if(err.response) {
                    if(err.response.status === 401 || err.response.status === 403 || err.response.status === 500) {
                        return
                    }
                }
                ErrorServerLog(err)
            })
    }

    updateLoggedIn = () => {
        var permissions = sessionStorage.getItem('permissions')
        this.setState({
            permissions: permissions ? permissions : "",
            loggedIn: permissions ? true : false
        })
    }

    logout = () => {
        sessionStorage.removeItem('permissions')
        Axios.get('https://api.barnes-dvf.fr/api/users/logout', {withCredentials: true})
            .then(res => this.setState({permissions:"", loggedIn:false}))
            .catch(err => {
                this.setState({permissions: "", loggedIn: false})
                ErrorServerLog(err)
            })
    }

    static getDerivedStateFromError(error) {
        return {error500: true}
    }

    componentDidCatch(error, info) {
        ErrorServerLog(error, info)
    }

    render () {
        if(this.state.error500) {
            return (<Error500 />)
        }
        return (
            <Router>
                <NavBar>
                    <NavLink link="/appdvf" text="Application" />
                    {this.state.loggedIn
                        ? (<NavButton onClick={() => this.logout()} text="Déconnexion" />)
                        : (<NavLink link="/login" text="Connexion" />)}
                    <NavLink link="/profil" text="Profil" />
                    {this.state.loggedIn ? (<a href="mailto:support@barnes-dvf.fr" className="nav-link nav-item">Signaler un Problème</a>) : null}
                    {this.state.permissions === "admin" ? (<NavLink link="/admin" text="Admin" />) : null}
                </NavBar>
                <main>
                    <Switch>
                        <Route path="/" exact render={() => <Index loggedIn={this.state.loggedIn}/>}  />
                        <Route path="/login" exact render={(routeProps) => (<LoginForm {...routeProps} update={this.updateLoggedIn} />)} />
                        <PrivateRoute path="/appdvf" exact component={DVFApp} loggedIn={this.state.loggedIn} />
                        <PrivateRoute path="/profil" exact component={Profil} loggedIn={this.state.loggedIn} />
                        <PrivateRoute path="/admin" component={Admin} componentProps={{loggedIn: this.state.permissions === "admin"}} loggedIn={this.state.permissions === "admin"} />
                        <Route component={Error404} />
                    </Switch>
                </main>
                <AppFooter />
            </Router>
        )
    }
}

export default App
